@use 'utils' as u;

.form-page-detail .form-block-detail {
    &.section-block {
        padding: u.$padding-main-section-detail;
        border-bottom: 1px solid $color--line;
        > .form-block-detail-title {
            @include font-14;
        }

        > .form-block-detail-content {
            margin: 8px 0;
            border: 1px solid $color--line;
            border-radius: 4px;
        }
    }

    //empty content block
    &:has(> .form-block-detail-content:empty) {
        display: none;
    }

    &.small-block {
        > .form-block-detail-title {
            @include font-14;
            @include fontSemibold;
            padding: 6px 12px;
            border-bottom: 1px solid $color--line;
        }
        > .form-block-detail-content {
            border-bottom: 1px solid $color--line;
        }
    }

    &.dieu-tri-dung-thuoc {
        padding: 16px 24px;

        .form-block-detail-title {
            @include font-16;
            @include fontSemibold;
            margin-bottom: 8px;
        }

        .attchments {
            border: 1px solid $color--line;
        }

        .form__row__detail {
            margin-bottom: 12px;
        }
    }
}
