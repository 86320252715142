$color--alabaster: #cdf3e4;
$color--alice-blue: #cdddfd;
$color--bg-blue: #e8edf6;
$color--bg-hover: #efefef;
$color--bg: #f9f9f9;
$color--black-8: rgba(0, 0, 0, 0.08);
$color--blue: #394bc7;
$color--green: #34ab71;
$color--ivory: #ffe0c7;
$color--line: #cccccc;
$color--line: #d8d8d8;
$color--orange: #ff9e2b;
$color--platinum: #f8d0cb;
$color--primary-dark: #1748a2;
$color--primary-light: #e4ebfb;
$color--primary: #1959cf;
$color--red: #ff6646;
$color--text-1: #000000;
$color--text-2: #696969;
$color--text-3: #9d9d9d;
$color--text-4: #5a5a5a;
$color--white: #ffffff;

/* ----- -------------------------------------------------------------------------------- */
/*
 * color text.
 */
$color--text-1_70: rgba(#000000, $alpha: 0.3);

$color--text-1_80: rgba(
    $color: $color--text-1,
    $alpha: 0.8,
);

/*
 * color gray.
 */
$color--background: var(--color-background);

/*
 * color status.
 */
$color--progress: var(--color-progress);
$color--success-default-hover: var(--color-success-default-hover);
$color--success-default: var(--color-success-default);

$color--light-blue: var(--color-light-blue);
$color--black-5: var(--color-black-5);
/*
 * shadow.
 */
$box-shadow--big: var(--box-shadow-big);
$inner-shadow--line-top: var(--inner-shadow-line-top);

/*
 * border radius.
 */
$border-radius--h48: var(--border-radius-h48);

/*
 * font.
 */
$font--regular: var(--font-regular);
$font--semibold: var(--font-semibold);
$font--bold: var(--font-bold);
$font--light: var(--font-light);
$font--medium: var(--font-medium);

/*
 * constant.
 */
$z-index--header: var(--z-index-header);

/*
* Set default constant
*/
$default-constant: (
    --z-index-header: 1000,
    --transition-common: all 0.3s ease,
    --height-header-lesson: 48px,
);
@mixin spread-map($map: ()) {
    @each $key, $value in $map {
        #{$key}: $value;
    }
}
:root {
    @include spread-map($default-constant);
}

/**
* transition
**/
$transition-common: all 0.3s ease;

$color--light_15: rgba(
    $color: $color--white,
    $alpha: 0.15,
);
$color--light_20: rgba(
    $color: $color--white,
    $alpha: 0.2,
);
$color--light_25: rgba(
    $color: $color--white,
    $alpha: 0.25,
);
$color--light_50: rgba(
    $color: $color--white,
    $alpha: 0.5,
);

$color--black: #000;

$color--black_04: rgba(
    $color: $color--black,
    $alpha: 0.04,
);

$color--light_10: rgba(
    $color: $color--black,
    $alpha: 0.1,
);
