.osn-table {
    display: flex;
    flex: 0 1 100%;
    min-height: 0;
    width: 100%;

    .min-height-0 {
        min-height: 0;
    }

    .w100 {
        width: 100%;
    }

    .ant-table-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .ant-table-sticky-scroll {
        display: none;
    }

    .ant-table-thead .ant-table-cell {
        padding: 8px;
    }
}

.ant-table-filter-dropdown {
    .search {
        box-shadow: $box-shadow--big;
        border-radius: 12px;
        padding: 16px;
        margin-top: 5px;
        &__input {
            border: 1px solid $color--line;
            box-sizing: border-box;
            border-radius: 6px;
            margin-bottom: 16px;
            max-width: 246px;
            display: block;
        }
        &__reset {
            border: none;
            margin-left: auto;
            color: $color--text-2;
            border: none;
        }
        &__done {
            background: $color--primary;
            border-radius: 6px;
            min-height: 32px;
            color: $color--white;
            border: none;
            &:hover {
                background: $color--primary-light;
            }
        }
    }
    .checkbox-group {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 230px;
        .ant-checkbox-wrapper {
            margin-left: 0;
        }
        .checkbox-wrapper {
            border-radius: 6px;
            padding: 6px;
            margin-bottom: 2px;
            &.checked,
            &:hover {
                background-color: rgba($color: $color--text-1, $alpha: 0.05);
            }
            &:last-child {
                margin-bottom: 16px;
            }
        }
        .ant-checkbox-checked .ant-checkbox-inner {
            border-radius: 4px;
            background-color: $color--primary;
            border-color: $color--primary;
        }
    }
}
.user-table {
    .role-cell {
        &.multirow {
            padding-left: 10px;
            position: relative;
            .items::before {
                top: 4px;
                left: 0px;
                content: '';
                width: 2px;
                height: calc(100% - 8px);
                border-radius: 1px;
                background-color: $color--text-3;
                position: absolute;
                display: block;
            }
        }
    }
    .actions-cell {
        display: flex;
        cursor: pointer;
    }
    .fullname-cell {
        display: flex;
        align-items: center;
        svg {
            margin-right: 8px;
            min-width: 16px;
            min-height: 16px;
        }
    }
}

.ems-pagination {
    background: $color--white;
    padding: 8px 12px;
    display: flex;
    width: 100%;
    z-index: 10;
    .ant-pagination-total-text {
        margin-right: auto;
    }
    .ant-pagination-item-link {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: $color--background;
        border-radius: 4px;
    }
    .ant-pagination-item:hover {
        border-color: $color--primary;
    }
    .ant-pagination-item-active {
        border-color: $color--primary;
    }
    .ant-pagination-jump-next
        .ant-pagination-jump-prev:focus-visible
        .ant-pagination-item-link-icon,
    .ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon,
    .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
    .ant-pagination-jump-next:hover {
        .ant-pagination-item-container {
            display: flex;
            height: 32px;
            align-items: center;
        }
        .ant-pagination-item-link-icon {
            color: $color--primary;
        }
    }
}
