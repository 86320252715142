.ant-modal {
    top: 25px;

    .ant-modal-content {
        padding: 0px;
        max-height: calc(100vh - 50px);

        .ant-modal-close {
            top: 0px;
            inset-inline-end: 0px;
        }

        .ant-modal-header {
            border-bottom: 1px solid $color--line;
            margin-bottom: 0px;
            .ant-modal-title {
                height: 32px;
                display: flex;
                align-items: center;
                padding: 0px 12px;
            }
        }

        .ant-modal-body {
            max-height: calc(100vh - 80px - 50px - 16px);
            overflow-y: auto;
            padding: 12px;
            padding-bottom: 0px;
            // Hidden scrollbar
            &::-webkit-scrollbar {
                display: none;
            }

            /* Hide scrollbar for IE, Edge and Firefox */
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */

            .ant-form {
                display: flex;
                flex-direction: column;
                row-gap: 6px;
                column-gap: 12px;
                flex-flow: row wrap;
                .ant-form-item {
                    margin-bottom: 0px;
                }
            }

            .form-item {
                flex-basis: calc(50% - 6px);

                &.full-width {
                    flex-basis: 100%;
                }
            }
        }

        .ant-modal-footer {
            padding: 12px;
            margin-top: 0px;

            .ant-btn-lg {
                padding: 8px 12px;
                height: 32px;
                @include font-12;
                @include fontRegular;
            }
        }
    }
}
