.form-page-detail .form-dataitem-detail {
    display: flex;
    border-bottom: 1px solid $color--line;
    &:last-child {
        border-bottom: none;
    }

    .form-dataitem-title {
        @include fontSemibold;
        @include font-14;
        width: 40%;
        padding: 6px 12px;
        border-right: 1px solid $color--line;

        @include for-under-lg {
            width: 100%;
            border-right: 0;
        }
    }

    .form-dataitem-value-group {
        width: 60%;
        padding: 6px 12px;
        @include for-under-lg {
            width: 100%;
        }
    }
    .form-dataitem-value {
        display: inline;
        &::after {
            content: '; ';
        }

        &.has-children {
            position: relative;
            &::after {
                content: ' / ';
            }
        }
    }
    .form-dataitem-value-wrapper {
        display: inline;
        &.has-children {
            display: block;
        }
        &:last-child {
            > .form-dataitem-value:not(.has-children):after {
                content: '';
            }
        }
        &.many-children {
            .form-dataitem-value {
                &.has-children {
                    &::before {
                        content: '';
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        background-color: $color--text-1;
                        border-radius: 50%;
                        position: relative;
                        top: 50%;
                        transform: translateY(-25%);
                        margin-right: 9px;
                    }
                }
            }
        }
    }
    .form-dataitem-children {
        display: inline;
        > .form-dataitem-value:last-child::after {
            content: '';
        }
    }

    @include for-under-lg {
        flex-wrap: wrap;
    }
}
.form-page-detail .file-input {
    margin: -6px -12px;
    border: none;
    border-radius: 0;
}
