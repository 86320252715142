@use 'utils' as u;

.form-page-detail .form-detail-table-2-column {
    border: 1px solid $color--line;
    border-radius: 4px;
    &:empty,
    &:not(
            :has(
                    :is(
                            .form-detail-table-2-column-row-value:not(:empty),
                            .form-table
                        )
                )
        ) {
        border: 0;
        &::after {
            content: 'Không có dữ liệu';
            display: flex;
            padding: 6px 12px;
            color: $color--text-2;
            border-radius: 4px;
            background: url('/assets/icons/no-data.svg') no-repeat center 18px;
            background-color: $color--black_04;
            height: 92px;
            justify-content: center;
            align-items: flex-end;
            padding-bottom: 16px;
        }
    }
    &-wrapper {
        padding: 16px 24px;

        border-top: none;
        @include for-under-lg {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
    &-title {
        @include font-16;
        @include fontSemibold;
        margin-bottom: 8px;
    }
    &-row {
        display: flex;
        border-bottom: 1px solid $color--line;
        &:last-child {
            border-bottom: none;
        }

        &-title {
            @include fontSemibold;
            @include font-14;
            width: 30%;
            padding: 6px 12px;
            border-right: 1px solid $color--line;

            @include for-under-lg {
                width: 100%;
                border-right: 0;
            }
        }

        &-value {
            width: 70%;
            > .form-detail-table-2-column-item,
            > .form-detail-table-2-column-item-checkbox {
                padding: 6px 12px;
                border-bottom: 1px solid $color--line;
                &:last-child {
                    border-bottom: none;
                }
            }

            @include for-under-lg {
                width: 100%;
            }
        }

        &:has(> .form-detail-table-2-column-row-value:empty) {
            display: none;
        }

        @include for-under-lg {
            flex-wrap: wrap;
        }
    }
    &-item {
        &-value {
            display: inline;
            span.font-medium {
                @include fontMedium;
            }
        }

        &-checkbox {
            > .form-detail-table-2-column-item-title {
                display: inline;
                @include fontMedium;
                &::after {
                    content: ': ';
                }
            }
            > .form-detail-table-2-column-item {
                display: inline;
                &::after {
                    content: '; ';
                }
                &:last-child::after {
                    content: '';
                }
            }
        }

        &-checkbox:has(> .many-children),
        &-checkbox:has(> .one-child) {
            .form-detail-table-2-column-item {
                &.level-2 {
                    display: inline;
                    &::after {
                        content: '';
                    }
                }
                &.level-2.one-child,
                &.level-2.many-children {
                    display: block;
                    > .form-detail-table-2-column-item-value::after {
                        content: '';
                    }
                    > .level-3 {
                        display: block;
                    }
                }
                &.level-2.no-child {
                    display: block;
                }
                &.level-3 {
                    display: block;
                    &::before {
                        @include u.detail-table-view-dot-line();
                    }
                    &:has(.level-4) {
                        > .form-detail-table-2-column-item-value::after {
                            content: ' / ';
                        }
                    }
                    > .level-4,
                    > .form-detail-table-2-column-item-checkbox {
                        display: inline;
                        &::after {
                            content: '; ';
                        }
                        &:last-child::after {
                            content: '';
                        }
                    }
                }
            }
        }

        &.level-2:has(.level-3:nth-last-child(2)) {
            .level-3::before {
                @include u.detail-table-view-dot-line();
            }
        }

        &.level-2.one-child {
            > .form-detail-table-2-column-item-value::after {
                content: ' / ';
            }
            .level-3 {
                display: inline;
            }
        }
    }

    .hidden-modal-items {
        display: none;
    }
}
