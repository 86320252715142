@font-face {
    font-family: 'Inter-regular';
    font-style: normal;
    src: local('Inter Regular'), local('Inter-Regular'),
        url('../assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter-regular';
    font-style: normal;
    font-weight: 300;
    src: local('Inter-Light'), local('Inter-Light'),
        url('../assets/fonts/Inter/Inter-Light.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter-regular';
    font-style: normal;
    font-weight: 400;
    src: local('Inter Medium'), local('Inter-Medium'),
        url('../assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter-regular';
    font-weight: 700;
    font-style: normal;
    src: local('Inter Bold'), local('Inter-Bold'),
        url('../assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter-regular';
    font-style: normal;
    font-weight: 600;
    src: local('Inter SemiBold'), local('Inter-SemiBold'),
        url('../assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
    font-display: swap;
}

@mixin fontLight {
    font-family: 'Inter-regular';
    font-weight: 300;
}

@mixin fontRegular {
    font-family: 'Inter-regular';
    font-weight: 400;
}

@mixin fontMedium {
    font-family: 'Inter-regular';
    font-weight: 500;
}

@mixin fontSemibold {
    font-family: 'Inter-regular';
    font-weight: 600;
}

@mixin fontBold {
    font-family: 'Inter-regular';
    font-weight: 700;
}

@mixin font-10 {
    font-size: 10px;
    line-height: 12px;
}

@mixin font-12 {
    font-size: 12px;
    line-height: 16px;
}

@mixin font-14 {
    font-size: 14px;
    line-height: 20px;
}

@mixin font-16 {
    font-size: 16px;
    line-height: 24px;
}

@mixin font-18 {
    font-size: 18px;
    line-height: 24px;
}

@mixin font-19 {
    font-size: 19px;
    line-height: 24px;
}

@mixin font-24 {
    font-size: 24px;
    line-height: 32px;
}
