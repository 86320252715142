.dform-file-input {
    border: 1px solid $color--line;
    border-radius: 4px;
    padding: 7px 11px;
    width: 100%;
    cursor: pointer;
    color: $color--primary;
    font-size: 12px;
    line-height: 16px;
    transition: all 0.3s ease;
    &:hover {
        border-color: $color--primary;
    }
    &.draging {
        border-style: dashed;
        border-color: $color--primary;
    }
    &__container {
        display: flex;
        align-items: center;
    }
    i {
        display: grid;
        min-width: 16px;
        margin-right: 8px;
    }
    &__text {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 8px;
    }
    &__btn-clear {
        display: grid;
        margin-left: auto;
        padding: 0px;
        color: $color--text-3;
        background-color: transparent;
        border: none;
        transition: all 0.3s ease;
        &:hover {
            color: rgba(0, 0, 0, 0.45);
        }
    }
    &.readOnly {
        cursor: default;
        .dform-file-input__btn-clear {
            cursor: not-allowed;
        }
    }
    &.disabled {
        cursor: not-allowed;
        .dform-file-input__btn-clear {
            cursor: not-allowed;
        }
    }
}
