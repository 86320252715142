@use 'sass:math';
@import 'responsive-display';

$grid-gap: 24px;
$col-gap: 24px;

$grid-gap-mobile: 16px;
$col-gap-mobile: 16px;

.form {
    &-page {
        padding: $grid-gap;
        @include for-under-md {
            padding: $grid-gap-mobile;
        }
    }
    &__row {
        margin: math.div(-$grid-gap, 2);
        display: flex;
        flex-wrap: wrap;
        @include for-under-md {
            margin: math.div(-$grid-gap-mobile, 2);
        }
    }
    &__col {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: math.div(-$col-gap, 2);
        @include for-under-md {
            margin: math.div(-$col-gap-mobile, 2);
        }

        > .form-dataitem,
        > .form__row {
            padding: math.div($col-gap, 2);
            @include for-under-md {
                padding: math.div($col-gap-mobile, 2);
            }
        }
        > .form-table {
            margin: math.div($col-gap, 2);
            @include for-under-md {
                margin: math.div($col-gap-mobile, 2);
            }
        }
        &:empty,
        &:not(:has(> :not(.form-element-hidden))) {
            display: none;
        }

        @for $grid from 1 through 12 {
            @for $span from 1 through 12 {
                &--#{$span}-#{$grid} {
                    padding: math.div($grid-gap, 2);
                    width: calc(($span / $grid) * 100% + $grid-gap);
                    @include for-under-lg {
                        @if $grid == 1 {
                            width: calc(100% + $grid-gap);
                        } @else {
                            width: calc($span * 50% + $grid-gap);
                        }
                    }
                    @include for-under-md {
                        padding: math.div($grid-gap-mobile, 2);
                    }
                }
                @include for-under-lg {
                    &--lg-#{$span}-#{$grid}.form__col {
                        @if $grid == 1 {
                            width: calc(100% + $grid-gap);
                        } @else {
                            width: calc($span * 50% + $grid-gap);
                        }
                    }
                }
                @include for-under-md {
                    &--#{$span}-#{$grid}.form__col {
                        width: calc(100% + $grid-gap-mobile);
                    }
                }
            }
            &--0-#{$grid} {
                padding: 0;
                width: 0;
            }
            @include for-under-lg {
                &--lg-0-#{$grid}.form__col {
                    width: 0;
                }
            }
        }
    }
    &-table {
        border-radius: 4px;
        border: 1px solid $color--line;
        margin-bottom: 12px;
        table {
            width: 100%;
            border-spacing: 0px;
        }
        &__row {
            border-bottom: 1px solid $color--line;
            height: 40px;
            display: table-row !important;
            &:last-child {
                border-bottom: none;
            }
            &.header {
                border-radius: 4px 4px 0px 0px;

                @for $i from 1 through 20 {
                    .w#{$i * 5}p {
                        width: calc($i * 5%);
                    }
                }
            }
            &.skeleton {
                color: $color--text-2;
                cursor: pointer;
            }
        }
        &__cell {
            display: table-cell;
            border-right: 1px solid $color--line;
            @include font-14;
            min-width: 40px;
            &:last-child {
                border-right: none;
            }
            &-inner {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;

                padding: 0 12px;
                &__item {
                    width: 100%;
                    height: 100%;

                    .ant-input-affix-wrapper,
                    .ant-picker,
                    .ant-input-number {
                        border: 0;
                        border-radius: 0;
                    }

                    .form-dataitem {
                        margin: 0 -12px;
                        &-message-error {
                            border: 1px solid red;
                        }
                        &-message-warning {
                            border: 1px solid yellow;
                        }
                    }
                }
                &.index {
                    justify-content: flex-end;
                    max-width: 48px;
                }
            }
            &.skeleton {
                opacity: 0.5;
                > div {
                    justify-content: flex-start;
                    padding-left: 12px;
                }
            }

            &.index,
            &.remove,
            &.add-trigger {
                > div {
                    justify-content: flex-end;
                }
            }

            @include for-under-lg {
                white-space: nowrap;
            }
        }
        @include for-under-lg {
            overflow-x: auto;
        }
    }

    &-dataitem-skeleton {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        min-width: 200px;
    }
    &-dataitem-option-skeleton {
        width: 120px;
        height: 24px;
        border-radius: 4px;
        margin-bottom: 6px;
    }
}

.xet-nghiem-khac {
    .form__col--1-4 {
        width: 25%;
    }
}
