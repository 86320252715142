.profile {
    width: 423px;
    padding: 48px;
    align-items: center;
    margin: auto;
    gap: 16px;

    &-title {
        color: $color--text-1;
        @include fontSemibold;
        @include font-24;
        margin-bottom: 12px;
    }

    .title {
        width: 90px;
        @include font-12;
        @include fontSemibold;
    }

    .value {
        @include font-12;
    }
}

.url-options {
    color: $color--primary;
    @include font-12;

    .logout {
        cursor: pointer;
        color: $color--primary;
    }
}
