.ant-popover-message-title {
    padding-left: 0;
}
.ant-popover-buttons {
    .cancel-button {
        width: 100px;
        height: 30px;
        background: $color--background;
        border-radius: 8px;
        border: none;

        &:hover {
            border: none;
            color: rgba(0, 0, 0, 0.85);
        }
    }
    .confirm-button {
        width: 100px;
        height: 30px;
        background: $color--success-default;
        color: $color--white;
        border-radius: 8px;
        border-color: $color--success-default;
        border: none;

        &:hover {
            border-color: $color--success-default;
        }
    }
}
