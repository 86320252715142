.recipient-layout {
    .patient-main-container {
        margin-left: 256px;
        width: calc(100vw - 256px);
        position: relative;
        min-height: 100vh;
        background: $color--white;
        transition: $transition-common;
        &.open {
            width: 100vw;
            margin-left: 0;
            transition: $transition-common;
        }
    }
}
