.ems-date-picker {
    background: $color--white;
    box-sizing: border-box;
    border-radius: 4px;
    &.ant-picker {
        &:hover {
            border-color: $color--primary;
        }
    }
    &.ant-picker-focused {
        border-color: $color--primary;
        box-shadow: none;
    }
}

.ems-range-date {
    margin-bottom: 24px;
}

.ems-picker-dropdown {
    .ant-picker-panel-container {
        background: $color--white;
        box-shadow: $box-shadow--big;
        border-radius: 8px;
    }
    .ant-picker-header-view {
        button:hover {
            color: $color--primary;
        }
    }
    .ant-picker-cell-inner {
        border-radius: 12px;
    }
    .ant-picker-cell-selected {
        .ant-picker-cell-inner {
            background: $color--primary;
        }
    }
    .ant-picker-cell-today {
        .ant-picker-cell-inner {
            &::before {
                border-color: $color--primary-light;
                border-radius: 12px;
            }
        }
    }
    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
            .ant-picker-cell-range-start
        ):not(.ant-picker-cell-range-end):not(
            .ant-picker-cell-range-hover-start
        ):not(.ant-picker-cell-range-hover-end)
        .ant-picker-cell-inner {
        background: $color--primary;
        cursor: pointer;
    }
    .ant-picker-footer {
        .ant-picker-today-btn {
            color: $color--primary;
        }
    }
}
