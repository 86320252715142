.menu-container {
    transition: $transition-common;
    .ant-menu-light .ant-menu-item:hover,
    .ant-menu-light .ant-menu-item-active,
    .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-light .ant-menu-submenu-active,
    .ant-menu-light .ant-menu-submenu-title:hover {
        color: $color--primary;
    }
    .ant-menu-item a:hover {
        color: $color--primary;
    }
    .ant-menu-submenu-title {
        display: flex;
        flex-direction: inherit;
        padding-left: 16px !important;
    }
    .ant-menu-sub .ant-menu-title-content {
        padding-left: 16px;
    }
    &.hide {
        display: none;
        transition: $transition-common;
    }
}
