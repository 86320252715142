.form-page {
    .checkbox-label {
        @include font-14;
        display: flex;

        .icon-warning {
            margin-left: 8px;
            color: $color--text-2;
        }

        .icon-expand {
            color: $color--text-2;
            display: flex;
            align-items: center;
            margin-left: 8px;
        }
    }

    .icon-suffix {
        cursor: pointer;
    }

    .border-left {
        border-left: 1px solid $color--line;
        padding-left: 15px;
    }
    .form__row {
        align-items: flex-start;
        margin-bottom: 16px;
        label + .form-dataitem {
            margin-top: 4px;
        }
    }
    .form-dataitem-title {
        @include fontSemibold;
        @include font-14;
        padding-bottom: 8px;
        display: flex;
        align-items: center;

        .icon-warning {
            margin-left: 8px;
            color: $color--text-2;
        }
    }

    .form-block-title {
        &:empty {
            display: none;
        }
    }
    .nest-options {
        margin-left: 7px;
        border-left: 1px solid $color--line;
        .form__row {
            margin-bottom: 0;

            &:has(.form-element-hidden) {
                margin-bottom: 12px;
            }

            .form-repeatable {
                padding-left: 12px;
                padding-right: 0px;

                .form-dataitem {
                    padding-left: 0px;
                    margin-left: 0px;
                }

                .common-input {
                    &--wrapper {
                        margin-bottom: 0px;
                    }
                }

                margin-bottom: 12px;
            }
        }

        .form-repeatable {
            padding-left: 12px;
            padding-right: 0px;

            .form-dataitem {
                padding-left: 0px;
                margin-left: 0px;

                .common-input {
                    &--wrapper {
                        margin-bottom: 0px;
                    }
                }
            }

            margin-bottom: 12px;
        }
        .form-dataitem {
            padding-left: 16px;
            margin-left: 7px;

            .common-input {
                &--wrapper {
                    margin-bottom: 12px;
                }
            }

            &.item-so-lan-nhap-vien {
                width: fit-content;
            }
        }

        .form-dataitem-message {
            margin-top: 0;
            padding-bottom: 10px;
            bottom: -15px !important;

            &.grid-area {
                border-left: 1px solid $color--line;
                padding-left: 16px;
                margin-left: 7px;
            }
        }

        .form-dataitem:last-child {
            padding-bottom: 4px;
        }

        .form-block-title {
            @include font-14;
            @include fontSemibold;
            margin: 0 0 8px 0;
            padding-top: 0;
            padding-left: 16px;
            margin-left: 7px !important;
            margin-bottom: 0px !important;
            padding-bottom: 8px;
            margin-top: 0px;
        }

        .form-static-element {
            padding-left: 16px;
            margin-left: 7px;

            .color-primary {
                color: $color--primary;
            }

            &.time-warning {
                border-left: 0;
                margin-left: 0;
                color: red;
                .form-static-element-element {
                    display: flex;
                    align-items: center;
                    .icon-warning {
                        display: flex;
                        align-items: center;
                        margin: 9px;
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            &.diem-nihss {
                border-left: 0;
                margin-left: 0;
            }
        }
    }
    .table-cham-soc-dac-biet {
        border-left: 1px solid $color--line;
        padding-left: 16px;
        margin-left: 7px;

        .form-table {
            .form-dataitem {
                border: 0;
                padding: 0;
                margin: 0;
                .common-input {
                    &--wrapper {
                        padding-bottom: 0;
                        margin: 0 -12px;
                    }
                }
            }
        }
    }
    .wrapper-row {
        padding: 16px;
        border: 1px solid $color--line;
        margin: 0 8px 16px 8px;
        border-radius: 6px;
        .form__col {
            padding: 0 8px;
        }
        .form__col:first-child {
            padding: 0 8px 0 0;
        }
        .form__col:last-child {
            padding: 0 0 0 8px;
        }
    }
    .split-input {
        padding: 0;
        .form__col:first-child {
            padding: 0;
            .ant-input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        .form__col:last-child {
            padding: 0;
            .ant-input {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            .form-dataitem-title {
                visibility: hidden;
            }
        }
    }

    .form-repeatable {
        padding-top: 16px;
        margin-left: 16px;
        padding-right: 12px;

        .form-repeatable-row-container {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .form-repeatable-row {
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: center;

                .form-repeatable-row-items {
                    flex: 1;
                    border-radius: 6px;
                    .form__col {
                        padding: 0 8px;
                    }
                    .form__col:first-child {
                        padding: 0 8px 0 0;
                    }
                    .form__col:last-child {
                        padding: 0 0 0 8px;
                    }
                }

                .form-repeatable-row-remove {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:hover {
                        color: red;
                    }
                    svg {
                        cursor: pointer;
                    }
                }
            }
        }

        .form-repeatable-add-row {
            z-index: 100;
            position: relative;
            margin-top: 6px;
            display: flex;
            align-items: center;

            color: $color--primary;
            cursor: pointer;
            span {
                margin-left: 10px;
            }
        }
    }

    .form-block {
        &-title {
            @include font-14;
            margin: 0px 0 12px 0;

            @include for-under-md {
                @include font-18;
                @include fontSemibold;
                margin-top: 0px;
            }
        }
        &.small-block {
            .form-block-title {
                @include font-14;
                @include fontSemibold;
                margin: 0 0 8px 0;
                padding-top: 0;
            }

            .form-block-content {
                padding-bottom: 12px;
            }

            .common-input--wrapper {
                padding-bottom: 12px;
            }

            .form-dataitem-message {
                margin: 0;
            }
        }
        &.hide-title-default {
            > .form-block-title {
                display: none;
            }
        }
        &.hide-title-under-lg {
            @include for-under-lg {
                > .form-block-title {
                    display: none;
                }
            }
        }
        &.hide-title-under-md {
            @include for-under-md {
                > .form-block-title {
                    display: none;
                }
            }
        }
    }

    .form-table {
        &.focus {
            box-shadow: 0 0 0 1px rgba($color: $color--primary, $alpha: 0.5);
        }
    }

    .form-dataitem-message {
        padding-top: 5px;
        &-error {
            color: red;
            @include font-12;
        }

        &-warning {
            color: yellow;
            @include font-12;
        }
    }

    .form-dataitem {
        &.hide-title-default {
            > .form-dataitem-title {
                display: none;
            }
        }
        &.hide-title-under-lg {
            @include for-under-lg {
                > .form-dataitem-title {
                    display: none;
                }
            }
        }
        &.hide-title-under-md {
            @include for-under-md {
                > .form-dataitem-title {
                    display: none;
                }
            }
        }
    }

    .group-data-item {
        .form-block-title {
            @include font-14;
            @include fontSemibold;
            margin: 0;
            padding-bottom: 8px;
        }

        .form-block-content {
            position: relative;
            display: grid;

            .form-dataitem {
                &:first-child {
                    .ant-input-affix-wrapper {
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                    }
                }

                &.last-item {
                    .ant-input-affix-wrapper {
                        border-bottom-left-radius: 4px;
                        border-bottom-right-radius: 4px;
                        border-bottom: 1px solid $color--line;
                    }
                }

                .form-dataitem-title {
                    display: none;
                }

                .ant-input-affix-wrapper {
                    border-radius: 0;
                    border-bottom: 0;
                    &-disabled {
                        background-color: inherit;
                        color: rgba(0, 0, 0, 0.1);
                        .ant-input-suffix {
                            color: rgba(0, 0, 0, 0.25);
                        }

                        .ant-input-disabled {
                            &::placeholder {
                                color: rgba(0, 0, 0, 0.25);
                            }
                        }
                    }
                }

                .common-input--wrapper {
                    color: $color--text-2;
                    padding: 0;

                    &.has-value {
                        color: $color--text-1;
                        .ant-input-suffix {
                            color: $color--text-1;
                        }
                    }
                }
            }
        }

        &.child-group-data {
            border-left: 1px solid $color--line;
            padding-left: 16px;
            margin-left: 16px;
            padding-top: 8px;
        }
    }

    @for $grid from 1 through 10 {
        .grid-area-#{$grid} {
            grid-area: $grid;
        }
    }

    .main-checkbox {
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
        margin: -24px;
        padding: 24px;
        margin-bottom: 0;
    }

    .hide {
        display: none;
    }
}

.required-mark {
    color: red;
    margin: 2px;
}

.submitButton {
    @include font-12;
    color: $color--white;
    border: 1px solid $color--primary;
    background-color: $color--primary;
    padding: 8px 16px;
    outline: none;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 16px;

    transition: 0.2s;

    &.btn-draft {
        color: $color--text-2;
    }
}
.submitButton:hover {
    color: $color--white;
    background-color: $color--primary-dark;
    outline: none;
    border: none;
}
.submitButton:focus {
    color: $color--white;
    background-color: $color--primary-dark;
    border: none;
}

.common-input--wrapper {
    .ant-input-number-affix-wrapper,
    .ant-picker {
        width: 100%;
    }
}

.form-dataitem {
    &.horizontal {
        > .common-input--wrapper {
            > .option-group-wrapper > .option-group {
                display: flex;
                flex-wrap: wrap;

                @include for-under-md {
                    .checkbox-option,
                    .radio-option {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.add-visit-form {
    display: flex;

    .ant-form-item {
        width: 50%;
        padding: 8px 24px;
        padding-right: 0px;
        &:last-child {
            padding-right: 24px;
        }
    }
}

.add-request-form {
    .ant-form-item {
        margin-bottom: 0px;
        .label {
            @include fontSemibold;
            margin-bottom: 8px;
        }
        .form-message-error {
            color: red;
            @include font-14;
            margin-top: 5px;

            &:empty {
                display: none;
            }
        }
    }
    .action-button {
        color: $color--primary;
        cursor: pointer;
        @include font-14;
        @include fontRegular;
    }
    .common-input--wrapper {
        display: flex;
    }
    .full-width {
        width: 100%;
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

.form-tooltip {
    .ant-tooltip-inner {
        padding: 12px 16px;
        background-color: $color--text-1;

        .tooltip-color-green {
            color: green;
            @include fontSemibold;
        }
    }
}

.main-checkbox {
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
    margin: -24px;
    padding: 24px;
    margin-bottom: 0;

    &.thu-thuat-chan-doan {
        .form-dataitem {
            padding-right: 24px;
        }
    }
}

.form-dataitem {
    &.icon-circle {
        > .form-dataitem-title > .form-dataitem-title-text {
            &::before {
                content: '';
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: $color--line;
                margin-left: 2px;
                margin-right: 14px;
                display: inline-block;
            }
        }

        &.has-value {
            .form-dataitem-title-text {
                &::before {
                    background-color: red;
                }
            }
        }
    }
}

.pt12 {
    padding-top: 12px;
}

.form-element-hidden {
    display: none !important;
}
